document.addEventListener('DOMContentLoaded', function() {
  var calendarEl = document.getElementById('calendar');

  var calendar = new FullCalendar.Calendar(calendarEl, {
      initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'title',
      center: '',
      right: 'today,prev,next'
    },

    displayEventTime: true, 
    displayEventEnd: true,
    eventDisplay: 'block',
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false
    },
    googleCalendarApiKey: 'AIzaSyDPTQCzU0RXZJB9Lj5P1dUClCin7SFmysU',
    events: 'sagaten2021@gmail.com',

    eventClick: function(arg) {
      arg.jsEvent.preventDefault()
    },
    locale: 'ja',

  //   loading: function(bool) {
  //     document.getElementById('loading').style.display =
  //       bool ? 'block' : 'none';
  //   }

  });
  calendar.render();
  changeView();

  function changeView(){
    if ($(window).width() < 768) {
      calendar.changeView('listMonth');
    } else {
      calendar.changeView('dayGridMonth');
    }
  }

  $(window).on('resize', function(){
    changeView();
  });
});
